/*
import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
*/



import { createStore, createLogger } from 'vuex'
//import cart from './modules/cart'
//import products from './modules/products'
import users from './modules/users'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    users,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
