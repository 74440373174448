import axios from 'axios';

export default {
    all() {
        return axios.get('/users');
    },
    find(id) {
        return axios.get(`/users/${id}`);
    },
    update(id, data) {
        return axios.put(`/users/${id}`, data);
    },
    delete(id, data) {
        return axios.delete(`/users/${id}`, data);
    },
    store(data) {
        return axios.post('/users/store', data);
    },
};
