// web link : https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules
//import Vue from 'vue'

export default class {
    //constructor ( endpoint ) {
    constructor ( endpoint ) {
        this.state = {
            /*
            endpoint: endpoint,
            meta:     {},
            status:   null,
            known:    [],
            currentId: null,
            */
            api: endpoint,
            all: [],
            //currentId: null,
            message: null,
            errors: null,
            exception: null,
            exceptionMessage: null,
            loading: false,
            serverInfo: null,
            resourceEditing: false,
            //fileUploading: false,
        }

        // ------------------------------------------------------------------------
        // getters

        this.getters = {

            // get resource from given ID
            getResourceById ( state, getters ) {
                return id => {
                    return getters.getSortedResources.find(obj => obj.id === id);
                }

            },

            getEditingStatus (state) {
                return state.resourceEditing
            },

/*
            getLoadingStatus (state) {
                console.log('get loading status')
                return state.loading

            },
*/

        } // this.getters

        // ------------------------------------------------------------------------
        // actions

        this.actions = {
            // function with context.commit in ES6 as argument
            getAllResources ( context ) {
                context.commit('SET_LOADING')

                context.state.api.all()
                    .then(response => {
                        context.commit('SET_RESOURCES', response.data.data)
                        context.commit('RESET_MESSAGES')
                    })
                    .catch(e => {
                        context.commit('SET_SERVER_ERRORS', e.response.data)
                    })
            },

            setCurrentResourceID ( context, id ) {
                context.commit('SET_CURRENT_ID', id)
            },

            deleteResource ( context, id ) {

                context.commit('RESET_MESSAGES')
                if (confirm("Do you really want to delete ?")) {

                    return new Promise((resolve, reject) => {

                        var index = context.state.all.findIndex(x => x.id === id);

                        context.state.api.delete(id)
                            .then((response) => {
                                resolve("delete OK")
                                // the payload has to be passed as an object
                                context.commit('DELETE_RESOURCE', {index: index, msg: response.data.message})
                            })
                            .catch(e => {
                                reject(new Error("Whoops!"))
                                context.commit('SET_SERVER_ERRORS', e.response.data)
                            })
                            .then(() => {
                                // Reset message after a few milliseconds
                                setTimeout(() => {
                                    context.commit('RESET_MESSAGES')
                                }, 2000)
                            })
                    })
                }
            },

            editResource ( context ) {
                context.commit('EDIT_RESOURCE')
            },
            cancelEditResource ( context ) {
                context.commit('CANCEL_EDIT_RESOURCE')
            },

            setLoading ( context ) {
                context.commit('SET_LOADING')
            },

/*
            uploadFile ( context ) {
                context.commit('UPLOAD_FILE')
            },
            cancelUploadFile ( context ) {
                context.commit('CANCEL_UPLOAD_FILE')
            },
*/

        } // this.actions

        // ------------------------------------------------------------------------
        // mutations

        this.mutations = {

            STORE_RESOURCE (state, payload) {
                state.all.push(payload.resource)
                state.message = payload.message
                state.resourceEditing = false
            },

            UPDATE_RESOURCE (state, payload) {
                /*
                var index = state.all.findIndex(x => x.id === payload.resource.id)
                Vue.set(state.all, index, payload.resource)
                */

                var index = state.all.findIndex(x => x.id === payload.resource.id)
                //var index = state.all.indexOf(payload.resource)
                state.all.splice(index, 1, payload.resource)

                state.message = payload.message
                state.resourceEditing = false
            },

            EDIT_RESOURCE ( state ) {
                state.resourceEditing = true
            },

            CANCEL_EDIT_RESOURCE ( state ) {
                state.resourceEditing = false
            },

            SET_RESOURCES ( state, payload ) {
                state.all = payload
            },

            SET_CURRENT_ID ( state, payload ) {
                state.currentID = payload
            },

            DELETE_RESOURCE ( state, payload ) {
                //console.log(payload)
                state.all.splice(payload.index, 1)
                state.message = payload.msg
            },

            SET_LOADING ( state ) {
                state.loading = true
            },

            SET_SERVER_ERRORS( state, payload ) {
                state.errors = payload.errors
                state.exception = payload.exception
                state.message = payload.message
                state.exceptionMessage = payload.exceptionMessage
                state.serverInfo = payload.serverInfo
            },

            RESET_MESSAGES ( state ) {
                state.loading = false
                state.message = null
                state.exceptionMessage = null
                //state.errors = null
                state.errors = false
                state.exception = null
                state.serverInfo = null

                //console.log(state.all)
            },

            UPLOAD_FILE ( state ) {
                state.fileUploading = true
            },

            CANCEL_UPLOAD_FILE ( state ) {
                state.fileUploading = false
            },

        } // this.mutations

    } //  constructor
}
