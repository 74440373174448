import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './plugins/auth.js';
import http from './http';
import VueFinalModal from 'vue-final-modal'

const app = createApp(App)

app
.use(http)
.use(store)
.use(router)
.use(auth)
.use(VueFinalModal())
.mount('#app')
