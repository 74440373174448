//import Crud from '/crud';
//var crud = new Crud('/api/users');

//import Vue from 'vue'
import api from '../../api/users'
//import mixins from './mixins'

import GlobalFunctions from './globalFunctions';
var globalFunctions = new GlobalFunctions(api);


// initial state
const state = () => ({
    // include common state to all resources
    ...globalFunctions.state,
    checkedUsers: [],
    activeUsers: [],
})

// ------------------------------------------------------------------------
// getters
const getters = {

    // include common getters to all resources
    ...globalFunctions.getters,

    // get sorted array of resources
    getSortedResources (state) {
        function compare(a, b) {

            // sort by name
            const argA = a.name.toUpperCase()
            const argB = b.name.toUpperCase()

            if (argA < argB)
                return -1;

            if (argA > argB)
                return 1;

            return 0;
        }
        //return state.all.concat().sort(compare);
        return state.all.sort(compare);
    },

    getSelectedUsers (state, getters) {
        let length = state.checkedUsers.length;
        var selectedUsersToShow = [];

        for (var i = 0; i < length; i++) {
            selectedUsersToShow[i] = state.checkedUsers[i];
        }

        return getters.getSortedResources.filter(function(usr) {
            return selectedUsersToShow.includes(usr.role);
        })
    },

    getUsersOptionList (state) {
        var options = []
        for (var i = 0; i < state.all.length; i++) {
                options.push({ text: state.all[i].name, value: state.all[i].id })
        }
        return options
    },
}

// ------------------------------------------------------------------------
// actions
const actions = {

    // include common actions to all resources
    ...globalFunctions.actions,

    // store new resource
    storeResource (context, user) {

        // return the expected promise from where the function is called
        return new Promise((resolve, reject) => {
            context.commit('RESET_MESSAGES')

            api.store({
                name: user.name,
                email: user.email,
                password: "hello",
                role: user.role
            })
            .then((response) => {
                resolve("done")
                context.commit('STORE_RESOURCE', response.data)
            })
            .catch(e => {
                reject(new Error("Whoops!"))
                context.commit('SET_SERVER_ERRORS', e.response.data)
            })
            .then(() => {
                // Reset message after a few milliseconds
                setTimeout(() => {
                    context.commit('RESET_MESSAGES')
                }, 2000)
            })
        })
    },

    // update existing resource
    updateResource ( context, user ) {
        // return the expected promise from where the function is called
        return new Promise((resolve, reject) => {
            context.commit('RESET_MESSAGES')

            api.update(user.id, {
                name: user.name,
                email: user.email,
                role: user.role
            })
            .then((response) => {
                resolve("done")
                context.commit('UPDATE_RESOURCE', response.data)
            })
            .catch(e => {
                reject(new Error("Whoops!"))
                context.commit('SET_SERVER_ERRORS', e.response.data)
            })
            .then(() => {
                // Reset message after a few milliseconds
                setTimeout(() => {
                    context.commit('RESET_MESSAGES')
                }, 2000)
            })
        })
    },

    updateCheckedUsers( context, checkedUsersArray ) {
        context.commit('UPDATE_CHECKED_USERS', checkedUsersArray)
    },
}

// ------------------------------------------------------------------------
// mutations
const mutations = {

    // include common mutations to all resources
    ...globalFunctions.mutations,

    UPDATE_CHECKED_USERS ( state, payload ) {
        state.checkedUsers = payload
    },
}
// ------------------------------------------------------------------------

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
